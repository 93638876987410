import { IEnvironment } from './interface';
import { version } from '../../../../package.json';

export const environment: IEnvironment = {
  production: false,
  authen: {
    appKey: 'MOBILE_HUTECH', // <-- ID from backend
    useMockData: false, //api, constant
    autoLoginByMockUser: false,
    reCapchaSecretKey: '',
    googleAuthClientID: '',
    facebookAuthSecretKey: '',
    useCustomLoginPageURL: '',
  },
  domain: {
    authDomain: 'https://tc-thp.api.ttithutech.com/api',
    urlWebLogIn: '',
    urlWebOld: '',
    apiTaiChinhThuHocPhiNhapHoc: 'https://tc-thp.api.ttithutech.com/api',
    importExcel: `https://tc-thp.api.ttithutech.com/api`,
  },
  application: {
    http: {
      // http request timeout
      timeout: 30 * 1000, // 30 giây
    },
    storage: {
      cookies: {
        expireInSeconds: 3600, // 1 hour
      },
    },
    package_version: version,
  },
};

// TOTO: Lưu dữ liệu khi user còn thao tác trên web thoát thì mất.
export const SessionStorageConfigKeys = {
  global: {},
  features: {
    taiChinhThuHocPhi: {
      thuHocPhi: {
        filters: 'features.taiChinhThuHocPhi.thuHocPhi.filters',
      },
      bankTransferError: {
        filters: 'features.taiChinhThuHocPhi.bankTransferError.filters',
        category: 'features.taiChinhThuHocPhi.bankTransferError.category',
      },
      xuLyTrongNgay: {
        filters: 'features.taiChinhThuHocPhi.xuLyTrongNgay.filters',
      },
      confirmPaymentViaBank: {
        filters: 'features.taiChinhThuHocPhi.confirmPaymentViaBank.filters',
      },
      refund: {
        filters: 'features.taiChinhThuHocPhi.refund.filters',
      },
      collectMoney: {
        filters: 'features.taiChinhThuHocPhi.collectMoney.filters',
      },
      changeMajor : {
        filters: "features.taiChinhThuHocPhi.changeMajor.filters"
      },
      dataBanDau : {
        filters: "features.taiChinhThuHocPhi.dataBanDau.filters"
      },
      tongHop: {
        filters: 'features.taiChinhThuHocPhi.tongHop.filters',
      },
      chiTiet: {
        filters: 'features.taiChinhThuHocPhi.chiTiet.filters',
      }
    },
    managePermission: {
      confApp: `features.managePermission.confApp.filters`,
      confApi: 'features.managePermission.confApi.filters',
      confMenu: 'features.managePermission.confMenu.filters',
      confRole: 'features.managePermission.confRole.filters',
      confUser: 'features.managePermission.confUserManage.filters',
    },
    /**
     * Current selected template at import excel
     */
    import_excel: {
      currentSelectedTemplate: 'features.import_excel.currentSelectedTemplate',
      filterImportExcel: 'features.import_excel.filterImportExcel',
      selectedTemplateUploadExcel:
        'features.import_excel.selectedTemplateUploadExcel',
    },

    log: {
      filters: {
        filter: 'features.log.filters.filter',
      }
    }
  },
};

// TODO: Lưu mấy dữ liệu mà tự động gửi lên server.
export const CookieStorageConfigKeys = {
  global: {

  },
  features: {
    taiChinhThuHocPhi: {

    }
  }
}

/**
 * Cấu hình khóa Local Storage cho lưu trữ dữ liệu.
 * Yêu cầu:
 *  - Cần đặt tên khóa đúng cú pháp để tránh sự trùng lặp tên khóa.
 *  - Cần viết mô tả cho khóa đã khai báo:
 *    vd: // Khóa lưu trữ <Nội dung thông tin> trong chức năng <Tên chức năng>
 *  - Cần quy hoạch khóa dựa theo chức năng (feature) hoặc toàn ứng dung (global):
 *  - Xem mẫu ở dưới nhé!!!
 * */
export const LocalStorageConfigKeys = {
  global: {
    transloco: {
      // Khóa lưu trữ ngôn ngữ hiện tại của Transloco.
      currentLanguage: 'global.transloco.currentLangulage',
    },
    layouts: {
      themeSetting: 'global.layouts.themeSetting',
      currentThemeMode: 'global.layouts.currentThemeMode'
    },
    routing: {
      // Khóa lưu trữ URL trước đó trong định tuyến sau khi login thành công.
      previousUrl: 'global.routing.previousUrl',
      devPreviousUrl: 'global.routing.devPreviousUrl',
    },
  },
  feature: {
    taiChinhThuHocPhi: {
      thuHocPhi: {
        caiDat: {
          chung: {
            canBoXuLy: 'features.taiChinhThuHocPhi.thuHocPhi.caiDat.chung.canBoXuLy',
            ngay: 'features.taiChinhThuHocPhi.thuHocPhi.caiDat.chung.ngay',
          },
          dongHocPhi: {
            serialNumber: 'features.taiChinhThuHocPhi.thuHocPhi.caiDat.dongHocPhi.serialNumber',
            noiDungBHYT: 'features.taiChinhThuHocPhi.thuHocPhi.caiDat.dongHocPhi.noiDungBHYT',
            noiDungHPHK: 'features.taiChinhThuHocPhi.thuHocPhi.caiDat.dongHocPhi.noiDungHPHK',
            soTienBHYT: 'features.taiChinhThuHocPhi.thuHocPhi.caiDat.dongHocPhi.soTienBHYT'
          },
          rutHocPhi: {
            noiDung: 'features.taiChinhThuHocPhi.thuHocPhi.caiDat.rutHocPhi.noiDung',
          },
          chuyenNganh: {
            noiDung: 'features.taiChinhThuHocPhi.thuHocPhi.caiDat.chuyenNganh.noiDung',
          },
          mayTram: {
            list: 'features.taiChinhThuHocPhi.thuHocPhi.caiDat.mayTram.list',
            value: 'features.taiChinhThuHocPhi.thuHocPhi.caiDat.mayTram.value',
          }
        },
      }
    }
  },
};

{
  "name": "hutech_tai-chinh-thu-hoc-phi",
  "version": "1.0.1",
  "scripts": {
    "ng": "ng",
    "start": "ng serve --configuration=development",
    "start:prod": "ng serve --configuration=production",
    "start:test": "ng serve --configuration=testing",
    "build": "ng build --configuration=production",
    "build:lan": "ng build --configuration=lan --output-hashing=all",
    "build:prod": "ng build --configuration=production --output-hashing=all",
    "build:prod-ip-206": "ng build --configuration=production-ip-206 --output-hashing=all",
    "build:prod-ip-132": "ng build --configuration=production-ip-132 --output-hashing=all",
    "build:test": "ng build --configuration=testing --output-hashing=all",
    "watch": "ng build --watch --configuration development",
    "test": "ng test",
    "test:karma": "ng test --watch",
    "server": "json-server --watch src/server/db.json --port 5000 --routes src/server/routes.json"
  },
  "private": true,
  "dependencies": {
    "@abacritt/angularx-social-login": "^2.2.0",
    "@angular/animations": "^17.0.7",
    "@angular/cdk": "^17.0.4",
    "@angular/common": "^17.0.5",
    "@angular/compiler": "^17.0.7",
    "@angular/core": "^17.0.7",
    "@angular/forms": "^17.0.7",
    "@angular/material": "^17.0.4",
    "@angular/platform-browser": "^17.0.7",
    "@angular/platform-browser-dynamic": "^17.0.7",
    "@angular/router": "^17.0.7",
    "@fullcalendar/core": "^6.1.9",
    "@fullcalendar/daygrid": "^6.1.9",
    "@fullcalendar/interaction": "^6.1.9",
    "@ngneat/transloco": "^5.0.7",
    "@taiga-ui/cdk": "^3.73.0",
    "@taiga-ui/core": "^3.73.0",
    "@taiga-ui/icons": "^3.73.0",
    "@taiga-ui/kit": "^3.73.0",
    "@types/trusted-types": "^2.0.7",
    "cors": "^2.8.5",
    "event-source-polyfill": "^1.0.31",
    "express": "^4.18.2",
    "express-fileupload": "^1.4.0",
    "file-saver": "^2.0.5",
    "flat": "^5.0.2",
    "http-server": "^14.1.1",
    "jquery": "^3.7.0",
    "lightgallery": "^2.7.1",
    "lodash": "^4.14.195",
    "moment": "^2.25.3",
    "moment-range": "^4.0.2",
    "ng-keyboard-shortcuts": "^13.0.8",
    "ng-recaptcha": "^13.2.1",
    "ngx-bootstrap": "^12.0.0",
    "rxjs": "^7.4.0",
    "sweetalert2": "^11.7.20",
    "tslib": "^2.3.0",
    "xlsx": "^0.18.5",
    "zone.js": "~0.14.2"
  },
  "devDependencies": {
    "@angular-devkit/build-angular": "^17.0.7",
    "@angular/cli": "^17.0.7",
    "@angular/compiler-cli": "^17.0.7",
    "@faker-js/faker": "^8.0.2",
    "@types/event-source-polyfill": "^1.0.3",
    "@types/file-saver": "^2.0.5",
    "@types/jasmine": "~4.3.0",
    "@types/jquery": "^3.5.16",
    "@types/lodash": "^4.14.195",
    "jasmine-core": "~4.6.0",
    "json-server": "^0.17.3",
    "karma": "~6.4.0",
    "karma-chrome-launcher": "~3.2.0",
    "karma-coverage": "~2.2.0",
    "karma-firefox-launcher": "^2.1.2",
    "karma-jasmine": "~5.1.0",
    "karma-jasmine-html-reporter": "~2.1.0",
    "karma-junit-reporter": "^2.0.1",
    "ts-node": "^10.9.1",
    "typescript": "~5.2.2"
  }
}
